import React from "react"
import { Banner } from "../page-components/about/banner"
import { CompanyProfile } from "../page-components/about/company-profile"
import { VisionMission } from "../page-components/about/vision-mission"
import SEO from "../components/seo"

const About = () => {
  return (
    <>
      <SEO title="About" />
      <Banner />
      <CompanyProfile />
      <VisionMission />
    </>
  )
}

export default About
