import { ClassNames, css } from "@emotion/core"
import styled from "@emotion/styled"
import { useTheme } from "emotion-theming"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import React, { useState } from "react"
import Expand from "react-expand-animated"
import { useMediaQuery } from "react-responsive"
import { mq, breakpoints } from "../../layouts/theme"
import { MetricsContainer } from "../../components/metrics-container"

const P = styled.p`
  text-align: justify;
  text-indent: 30px;
  font-family: ProximaNova-Regular;
  margin-bottom: 0px;
`
export const CompanyProfile = () => {
  const [open, setOpen] = useState(false)
  const {
    colors: {
      txt: { link },
      bg: { lightGrey },
    },
  } = useTheme()
  const { banner, briefCase, house, location, years } = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "tektite-office.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      briefCase: file(relativePath: { eq: "briefcase-icon.png" }) {
        ...Icon
      }
      house: file(relativePath: { eq: "house-icon.png" }) {
        ...Icon
      }
      location: file(relativePath: { eq: "location-icon.png" }) {
        ...Icon
      }
      years: file(relativePath: { eq: "years-icon.png" }) {
        ...Icon
      }
    }
  `)
  const isMobile = useMediaQuery({ minWidth: breakpoints.mobile })
  const secondPara = (
    <P
      css={css`
        margin-top: 10px;
      `}
    >
      We allocate substantial resources for this purpose to make sure that our
      market is satisfied and that we are able to deliver the best values for
      their investments. Our sites are carefully selected to ascertain
      accessibility to major roads and transportation networks, commercial
      establishments, institutions and employment zones. Our designs are planned
      carefully by a team of professionals with excellent reputation in their
      fields. Our in-house organization boasts of the best minds in the industry
      with extensive decades-long experience in housing.
    </P>
  )
  return (
    <section
      id="history"
      css={css`
        /* added this since safari does not support scroll-margin-top */
        /* for sticky header */
        margin-top: -63px;
        padding-top: 63px;
      `}
    >
      <MetricsContainer
        metrics={[
          { icon: location, label: "UNIQUE LOCATIONS", num: 4 },
          { icon: briefCase, label: "PROJECTS COMPLETED", num: 8 },
          { icon: house, label: "TOWNOUSE UNITS", num: 1249 },
          { icon: years, label: "YEARS OF SERVICE", num: 28 },
        ]}
      />
      <div
        css={css`
          display: flex;
          flex-direction: column;
          background-color: ${lightGrey};
          ${mq.mobile} {
            flex-direction: row;
            align-items: stretch;
          }
          ${mq.tablet} {
          }
        `}
      >
        <ClassNames>
          {({ css }) => (
            <BackgroundImage
              Tag="div"
              className={css`
                background-position: top;
                width: 100%;
                height: 200px;
                background-size: cover;
                ${mq.mobile} {
                  /* height: 100%; */
                  height: unset;
                  flex: 2;
                  background-position: center;
                }
                ${mq.tablet} {
                  flex: 3;
                }
              `}
              fluid={banner.childImageSharp.fluid}
            />
          )}
        </ClassNames>
        <div
          css={css`
            padding: 40px 30px;
            ${mq.mobile} {
              flex: 3;
              padding: 45px 30px;
            }
            ${mq.tablet} {
              padding: 45px 60px;
            }
          `}
        >
          <h3
            css={css`
              text-align: center;
              margin-bottom: 20px;
              font-family: ProximaNova-Bold;
              ${mq.mobile} {
                text-align: left;
              }
            `}
          >
            Company Profile
          </h3>
          <P>
            First Shelter Ventures and Realty Corporation started as a real
            estate planning company in 1992 and later evolved into a property
            development firm. Having been in the business of project packaging,
            planning, and preparation of comprehensive real estate project
            feasibility studies that includes technical, financial, land, market
            research, studies and plans for many years, our company is committed
            to developing only carefully evaluated and planned projects that
            will best serve the needs of our market. We spend enormous time on
            planning and market research to ensure that our products meet the
            high standards of our valued clients.
          </P>
          {isMobile ? (
            secondPara
          ) : (
            <>
              <Expand open={open}>{secondPara}</Expand>
              {!open ? (
                <button
                  css={css`
                    border: none;
                    outline: none;
                    background: none;
                    color: ${link};
                    padding: 0;
                    margin: 0;
                  `}
                  onClick={() => setOpen(true)}
                >
                  Read More..
                </button>
              ) : null}
            </>
          )}
        </div>
      </div>
    </section>
  )
}
