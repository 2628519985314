import { css } from "@emotion/core"
import React from "react"
import { useTheme } from "emotion-theming"
import Img from "gatsby-image"
import { mq } from "../layouts/theme"

export const MetricsContainer = ({ metrics }) => {
  const {
    colors: {
      bg: { primary },
    },
  } = useTheme()
  return (
    <div
      css={css`
        background: ${primary};
        padding: 20px 40px;
        display: flex;
        ${mq.tablet} {
          padding: 40px 5px;
        }
        ${mq.desktop} {
          padding: 40px;
        }
      `}
    >
      <div
        css={css`
          display: grid;
          grid-template-columns: 1fr;
          margin: 0 auto;
          place-items: center;
          ${mq.mobile} {
            place-items: unset;
            grid-template-columns: 250px 250px;
            width: 550px;
            grid-column-gap: 50px;
          }
          /* ${mq.desktop} {
            width: unset;
            margin: 0 auto;
            grid-column-gap: 50px;
            grid-template-columns: 1fr 1fr 1fr 1fr;
          } */
          ${mq.desktop} {
            display: flex;
            margin: 0 auto;
            width: unset;
            max-width: 1200px;
            grid-column-gap: unset;
            grid-template-columns: unset;
            .metric + .metric {
              margin-left: 60px;
            }
          }
        `}
      >
        {metrics.map(({ num, icon, label }) => {
          return (
            <div
              key={label}
              className={"metric"}
              css={css`
                display: flex;
                align-items: center;
                min-width: 200px;
                margin: 10px 0px;
                ${mq.mobile} {
                  margin: 15px 0px;
                }
              `}
            >
              <Img
                css={css`
                  width: 25px;
                  margin-right: 10px;
                  ${mq.mobile} {
                    margin-right: 15px;
                    width: 30px;
                  }
                  ${mq.tablet} {
                    width: 35px;
                    margin-right: 17px;
                  }
                `}
                fluid={icon.childImageSharp.fluid}
                alt=""
              />
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  ${mq.mobile} {
                    align-items: unset;
                    flex-direction: column;
                  }
                `}
              >
                <h4
                  css={css`
                    margin: 0;
                    text-transform: uppercase;
                    margin-right: 5px;
                    color: white;
                    font-family: ProximaNova-Bold;
                    ${mq.mobile} {
                      margin-bottom: 2px;
                    }
                  `}
                >
                  {num}
                </h4>
                <h5
                  css={css`
                    margin: 0;
                    color: white;
                    text-transform: uppercase;
                    font-family: ProximaNova-Bold;
                  `}
                >
                  {label}
                </h5>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
