import React from "react"
import BackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"
import { ClassNames } from "@emotion/core"
import { mq } from "../../layouts/theme"

export const Banner = () => {
  const { banner } = useStaticQuery(graphql`
    fragment Icon on File {
      childImageSharp {
        fluid(maxWidth: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    query {
      banner: file(relativePath: { eq: "about-banner.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <section>
      <ClassNames>
        {({ css }) => (
          <BackgroundImage
            Tag="div"
            className={css`
              background-position: center;
              width: 100%;
              height: 250px;
              background-size: cover;
              ${mq.mobile} {
                height: 300px;
              }
              ${mq.tablet} {
                height: 350px;
              }
            `}
            fluid={banner.childImageSharp.fluid}
          />
        )}
      </ClassNames>
    </section>
  )
}
