import React from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import { mq } from "../../layouts/theme"

const Col = styled.div`
  display: flex;
  flex-direction: column;
  ${mq.mobile} {
    flex-basis: 48%;
  }
`

const H4 = styled.h4`
  font-family: ProximaNova-Bold;
  margin-bottom: 10px;
  color: ${({
    theme: {
      colors: {
        txt: { link },
      },
    },
  }) => link};
  font-size: 18px;
`

export const VisionMission = () => {
  const firstCol = [
    {
      title: "Vision",
      body:
        "The company’s vision is to become one of the country’s premier property developers with a reputation for innovative concepts and excellent products.",
    },
    {
      title: "Mission",
      body:
        "First Shelter is dedicated to the demonstration of the corporate concept of linked prosperity.",
    },
  ]

  const secondCol = [
    {
      title: "Product Mission",
      body:
        "To acquire, develop and market property to serve as the legacy of the target market to the next generation, guided by a continued commitment to promoting business practices that respect the earth and the environment.",
    },
    {
      title: "Economic Mission",
      body:
        "To operate the company on a sustainable basis of profitable growth, increasing value for our stakeholders and creating as well as expanding opportunities for career and financial growth for our employees.",
    },
    {
      title: "Social Mission",
      body:
        "To operate the company on a sustainable basis of profitable growth, increasing value for our stakeholders and creating as well as expanding opportunities for career and financial growth for our employees.",
    },
  ]

  return (
    <section
      css={css`
        padding: 40px 40px;
      `}
      id="vision-mission"
    >
      <h3
        css={css`
          text-align: center;
          margin: 0;
          margin-bottom: 40px;
          font-family: ProximaNova-Bold;
          text-align: center;
        `}
      >
        Vision & Mission
      </h3>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          ${mq.mobile} {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            max-width: 1200px;
            margin: 0 auto;
          }
        `}
      >
        <Col>
          {firstCol.map(({ title, body }, i) => {
            return (
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  margin-bottom: 15px;
                  ${mq.mobile} {
                    margin-bottom: ${i + 1 === firstCol.length ? "0px" : "20px"};
                  }
                `}
                key={i}
              >
                <H4>{title}</H4>
                <p>{body}</p>
              </div>
            )
          })}
        </Col>
        <Col>
          {secondCol.map(({ title, body }, i) => {
            return (
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  margin-bottom: ${i + 1 === secondCol.length ? "0px" : "15px"};
                `}
                key={i}
              >
                <H4>{title}</H4>
                <p>{body}</p>
              </div>
            )
          })}
        </Col>
      </div>
    </section>
  )
}
